<template>
    <div class="report-submit">
        <StackRouterHeaderBar />
        <h1 class="title m-b-40" v-html="title" />

        <main class="main" :class="{ focused: !showButtons }">
            <div class="desc m-b-12">
                신고사유: <span class="c-primary f-bold">{{ reason.name }}</span>
                <span v-if="canSkip" class="optional">(선택)</span>
            </div>

            <TextareaWithX
                class="m-b-32"
                v-model="detailReason"
                :placeholder="'신고 사유를 작성해주세요'"
                @focus="onFocus"
                @blur="onBlur"
            />

            <div class="photos m-b-36">
                <div class="attachment m-b-12">사진 첨부<span>(선택)</span></div>
                <div class="photos-wrapper">
                    <div
                        class="photo"
                        :class="{ empty: !photo.src }"
                        v-for="(photo, idx) in photos"
                        :key="photo.key"
                        @click="onClickPhoto(idx)"
                    >
                        <i v-if="!photo.url" class="material-icons">add</i>
                        <img v-else :src="photo.url" />
                    </div>
                </div>
            </div>
        </main>

        <footer v-show="showButtons" class="footer">
            <button
                class="btn"
                :class="{ 'm-b-10': hideSkipButton }"
                v-html="$translate('REPORT')"
                :disabled="!buttonEnabled"
                @click="onClickSubmit"
            />
            <button
                v-if="hideSkipButton"
                class="btn btn-skip"
                v-html="$translate('SKIP_AND_REPORT')"
                @click="onClickSubmit"
            />
        </footer>
        <input
            ref="imageUploader"
            type="file"
            class="image display-none"
            @change="onChangeImage($event)"
            accept="image/*"
        />
    </div>
</template>

<script>
import userService from '@/services/user'
import agentService from '@/services/agent'

export default {
    name: 'ReportSubmitage',
    props: {
        reason: {
            type: Object,
            required: true,
        },
        type: {
            type: String,
            required: true,
            validator: function (value) {
                return value === 'user' || value === 'agent'
            },
        },
        target: {
            type: Object,
            required: true,
        },
        reportId: {
            type: Number,
            required: true,
        },
    },
    data: () => ({
        photos: [],
        detailReason: '',
        showButtons: true,
    }),
    computed: {
        canSkip() {
            return this.reason.name !== '기타'
        },
        title() {
            if (this.type === 'user') return this.$translate('REPORT_PROFILE')
            if (this.type === 'agent') return this.$translate('REPORT_AGENTS')

            return ''
        },
        buttonEnabled() {
            if (!this.canSkip) return this.detailReason.length > 0

            return this.detailReason.length > 0 || this.photos.some(p => p.url)
        },
        hideSkipButton() {
            return this.canSkip && !this.buttonEnabled
        },
    },
    methods: {
        initPhotos() {
            return Array(2)
                .fill({})
                .map((photo, idx) => {
                    return {
                        key: idx + 1,
                        url: '',
                        blob: null,
                        name: '',
                    }
                })
        },
        onChangeImage(event) {
            const file = event.target.files[0]

            const selectedPhoto = this.photos[this.selectedPhotoIndex]
            selectedPhoto.name = file.name
            selectedPhoto.blob = file

            const reader = new FileReader()
            reader.onload = () => {
                selectedPhoto.url = reader.result
            }

            reader.readAsDataURL(file)

            this.$refs.imageUploader.value = ''
            this.selectedPhotoIndex = null
        },
        onClickPhoto(index) {
            if (this.photos[index].url) {
                this.$actionSheet({
                    buttons: [
                        {
                            label: '지우기',
                            class: 'c-red',
                            handler: () => this.removePhoto(index),
                        },
                    ],
                })

                return
            }

            this.selectedPhotoIndex = index
            this.$refs.imageUploader.click()
        },
        async onClickSubmit() {
            if (this.buttonEnabled) {
                try {
                    this.$loading(true)
                    const payload = this.populatePayload()
                    let message

                    if (this.type === 'user') {
                        const { msg } = await userService.reportUser.update(this.target.id, this.reportId, payload)
                        message = msg
                    } else if (this.type === 'agent') {
                        const { msg } = await agentService.reportAgent.update(
                            this.target.agent_id,
                            this.reportId,
                            payload,
                        )
                        message = msg
                    }
                    this.$toast.success(message)
                    this.$stackRouter.clear()
                } catch (e) {
                    console.error(e)
                } finally {
                    this.$loading(false)
                }
            } else {
                this.$toast.success('COMPLETE_SUBMIT')
                this.$stackRouter.clear()
            }
        },
        populatePayload() {
            const formData = new FormData()

            if (this.photos.length > 0) {
                this.photos
                    .filter(p => p.blob)
                    .forEach((photo, idx) => {
                        formData.append(`photo${idx + 1}`, photo.blob)
                    })
            }

            if (this.detailReason.length > 0) {
                formData.append('detail', this.detailReason)
            }

            return formData
        },
        removePhoto(index) {
            const emptyPhoto = {
                key: index + 1,
                url: '',
                blob: null,
                name: '',
            }

            this.photos.splice(index, 1, emptyPhoto)
        },
        onFocus() {
            this.showButtons = false
        },
        onBlur() {
            setTimeout(() => {
                this.showButtons = true
            }, 100)
        },
    },
    mounted() {
        this.photos = this.initPhotos()
    },
}
</script>

<style lang="scss" scoped>
.report-submit {
    $header-height: 128px;
    $footer-height: 142px;

    .title {
        margin-left: 16px;
        font-size: 24px;
        color: black;
        font-weight: 500;

        @include f-medium;
    }

    .main {
        padding: 0 16px;
        height: calc(100% - #{$header-height} - #{$footer-height});
        overflow-y: auto;

        &.focused {
            height: calc(100% - #{$header-height});
        }

        .desc {
            color: $grey-08;
            font-size: 13px;

            .optional {
                font-size: 12px;
                color: $grey-06;
            }
        }

        .textarea-with-x {
            height: 200px;

            textarea {
                padding: 0;
            }
        }

        .attachment {
            color: $grey-08;
            font-size: 13px;

            @include f-medium;

            span {
                margin-left: 2px;
                color: $grey-06;
                font-size: 12px;

                @include f-regular;
            }
        }

        .photos-wrapper {
            display: flex;
            $photo-size: calc((100vw - (32px + 8px)) / 4);

            .photo {
                width: $photo-size;
                height: $photo-size;
                border-radius: 8px;
                border: solid 1px $grey-02;
                overflow: hidden;

                @include center;

                .material-icons {
                    color: $grey-05;
                }

                &.empty {
                    background-color: $grey-01;
                    border: solid 1px $grey-02;
                }

                &:not(:last-child) {
                    margin-right: 8px;
                }

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    .footer {
        position: absolute;
        bottom: 0;
        width: 100vw;
        max-height: $footer-height;
        padding: 16px;
        padding-bottom: 20px;
        border-top: solid 1px $grey-02;
        background-color: white;

        .btn {
            width: 100%;
            height: 48px;
            font-size: 15px;

            &:not(.btn-skip):not(:disabled) {
                background-color: $purple-primary;
                color: white;
            }
        }

        .btn-skip {
            font-size: 15px;
            color: $grey-08;
            border: solid 1px $grey-03;

            @include f-medium;
        }
    }
}
</style>
